import { GLOBALS } from '@/constants'
import pagheViews from '@paghe/views'
/* import { pagheRoutes } from '@/routes' */
const tabPropsDefault: TabProps = {
    GROUP: 'uniemensDipte',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'UNIEMENS DIPTE CIG',
    CODPERMESSO: 'RII_DIPTE',
}

function propsFn(route) {
    const params = {
        ...route.params,
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: route.params.day ? 1 : Number(route.params.day),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        mod: route.params.mod ?? GLOBALS.DATA_MODE.MOD,
        id: Number(route.params.id),
    }

    /*  if (route.name != pagheRoutes.uniemens.dipendente.SETTINGS.LISTA.NAME) {
        params.mod = route.params.mod ?? GLOBALS.DATA_MODE.MOD
        params.id = Number(route.params.id)
    }
 */
    return params
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Dettaglio Dipendenti - Uniemens - Dipendente - Gestione - Paghe',
        LABEL: 'Dettaglio dipendente',
        PATH: 'uniemensDipteCig/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'uniemensDipteCigDettaglio',
        TAB: { ...tabPropsDefault },
    },
    GENERALE: {
        TITLE: '',
        LABEL: 'Generale',
        PATH: 'generale',
        NAME: 'uniemensGeneraleCig',
        TAB: { ...tabPropsDefault },
    },
    IMPONIBILE: {
        TITLE: '',
        LABEL: 'Imponibile / Contributi',
        PATH: 'imponibile',
        NAME: 'uniemensImponibileCig',
        TAB: { ...tabPropsDefault },
    },
    SETTIMANA: {
        TITLE: '',
        LABEL: 'Settimane / Differenze accredito',
        PATH: 'settimane',
        NAME: 'uniemensSettimaneCig',
        TAB: { ...tabPropsDefault },
    },

    INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG: {
        TITLE: '',
        LABEL: 'Eventi CIG/Info agg. causali contributi',
        PATH: 'InfoAggCausaliContribEventiCig',
        NAME: 'uniemensInfoAggCausaliContribEventiCigCig',
        TAB: { ...tabPropsDefault },
    },

    /*  PREAVVISO_BONUS_ESTERO: {
        TITLE: '',
        LABEL: 'Preavviso / Bonus / Estero',
        PATH: 'PreavvisoAppaltiBonusEstero',
        NAME: 'uniemensPreavvisoAppaltiBonusEstero',
        TAB: { ...tabPropsDefault },
    },

    CONTRIB_SIND_CONTR_ATIPICA: {
        TITLE: '',
        LABEL: 'Contrib.Sind / Contr.Atipica',
        PATH: 'ContribSindContrAtipica',
        NAME: 'uniemensContribSindContrAtipica',
        TAB: { ...tabPropsDefault },
    },

    LAV_DOMICILIO: {
        TITLE: '',
        LABEL: ' Lav.Domicilio',
        PATH: 'LavDomicilio',
        NAME: 'uniemensLavDomicilio',
        TAB: { ...tabPropsDefault },
    },
    FONDI_SPECIALI: {
        TITLE: '',
        LABEL: 'Fondi Speciali',
        PATH: 'FondiSpeciali',
        NAME: 'uniemensFondiSpeciali',
        TAB: { ...tabPropsDefault },
    },
    TFR_MESE_TFR_DESTINAZIONE: {
        TITLE: '',
        LABEL: 'TFR Mese / TFR Destinazione',
        PATH: 'TfrMeseTfrDestinazione',
        NAME: 'uniemensTfrMeseTfrDestinazione',
        TAB: { ...tabPropsDefault },
    }, */

    SPORT_SPETTACOLO: {
        TITLE: '',
        LABEL: 'Sport / Spettacolo',
        PATH: 'SportSpettacolo',
        NAME: 'uniemensSportSpettacoloCig',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: pagheViews.uniemens.dipendenteCig.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.GENERALE.LABEL,
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    tab: SETTINGS.GENERALE.TAB,
                    component: pagheViews.uniemens.dipendenteCig.dettaglio.childs.Generale,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP01',
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
                {
                    label: SETTINGS.IMPONIBILE.LABEL,
                    path: SETTINGS.IMPONIBILE.PATH,
                    name: SETTINGS.IMPONIBILE.NAME,
                    tab: SETTINGS.IMPONIBILE.TAB,
                    component: pagheViews.uniemens.dipendenteCig.dettaglio.childs.Imponibile,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP02',
                    },
                    orderWizard: 1,
                    props: propsFn,
                },
                {
                    label: SETTINGS.SETTIMANA.LABEL,
                    path: SETTINGS.SETTIMANA.PATH,
                    name: SETTINGS.SETTIMANA.NAME,
                    tab: SETTINGS.SETTIMANA.TAB,
                    component: pagheViews.uniemens.dipendenteCig.dettaglio.childs.Settimana,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP03',
                    },
                    orderWizard: 2,
                    props: propsFn,
                },
                {
                    label: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.LABEL,
                    path: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.PATH,
                    name: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.NAME,
                    tab: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.TAB,
                    component:
                        pagheViews.uniemens.dipendenteCig.dettaglio.childs
                            .InfoAggCausaliContribEventiCig,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP04',
                    },
                    orderWizard: 3,
                    props: propsFn,
                },

                {
                    label: SETTINGS.SPORT_SPETTACOLO.LABEL,
                    path: SETTINGS.SPORT_SPETTACOLO.PATH,
                    name: SETTINGS.SPORT_SPETTACOLO.NAME,
                    tab: SETTINGS.SPORT_SPETTACOLO.TAB,

                    component: pagheViews.uniemens.dipendenteCig.dettaglio.childs.SportSpettacolo,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP10',
                    },
                    orderWizard: 8,
                    props: propsFn,
                },
            ],
        },
    ],
}
