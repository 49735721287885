import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/ProspettoContabileRiga'

class API {
    @call({ url: `${BASE_URL}/GetProspettoContabileRiga` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<ProspettoContabileRiga>> | null> {
        return null
    }

    // @call({ url: `${BASE_URL}/SaveProspettoCOntabileRiga` })
    // static async save(
    //     payload: SaveProspettoContabileCodCommand,
    // ): Promise<AxiosResponse<BasicResponsePayload<SaveProspettoContabileCodCommand>> | null> {
    //     return null
    // }
}

export default {
    get: API.get,
    // save: API.save,
}
